@import 'src/styles/index';

.edit-sub-container {
  width: 70%;
  min-width: 700px;
  max-width: 900px;
}

.mt-30 {
  margin-top: 30px;
}

.DP-image {
  height: 40px;
  width: 60px;
  min-height: 40px;
  min-width: 60px;
  margin: 20px 0 15px 0;
  padding: 0 10px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}
.sub-tag {
  * {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 14px;
    font-size: 10px;
    width: 77px;
    text-align: center;
    margin-right: 5px;
    line-height: 1;
  }
  .monthly {
    background: #12143f;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.7rem;
    line-height: 0.7rem;
  }
  .act {
    background: #43c643;
    color: #fff;
    font-weight: 600;
    font-size: 0.7rem;
    line-height: 0.7rem;

  }
  .vic {
    background: #f59e13;
    color: #fff;
    font-weight: 600;
    font-size: 0.7rem;
    line-height: 0.7rem;
  }
}

.edit-subscribers-right-container {
  width: calc(100% - 60px);
  padding: 0 10px;

.admin-detail {
  display: flex;

  div {
    flex: 0 0 27%;
    min-width: 140px;
  }
}
}

.edit-subscriber-buttons-row button {
  padding: 10px 30px;
}

.edit-subscription-date {
  position: relative;
}

.edit-subscription-duration {
  margin: 5px 0 0 5px ;
  font-size: 14px;
  font-weight: 400;
  color: $placeholder-color;
}
