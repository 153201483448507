@import "src/styles/index";

.admin-title {
  margin-bottom: 5px;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 600;
  color: $placeholder-color;
}

.admin-table {
  width: 100%;
  font-size: 14px;
  font-weight: 500;

  select {
    min-width: 100%;
    padding: 2px 10px;
    border: none;
    outline: none;
  }

  img {
    height: 38px;
    max-height: 38px;
  }

  .row-container {
    max-height: calc(100vh - 380px);
    overflow-y: auto;
  }

  .admin-table-details {
    display: grid;
   // grid-template-columns: calc(40% - 85px) calc(35% - 15px) calc(25% - 15px) 70px;
    grid-template-columns: calc(40% - 85px) calc(30% - 15px) calc(28% - 15px) 100px;
    align-items: flex-end;
    gap: 15px;
  }

  .tr {
    align-items: center;
    padding: 15px 20px;
    margin-bottom: 10px;
    background-color: $secondary-variant-color;
    border-radius: 5px;

    div {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .heading {
    margin-bottom: 5px;
    padding: 0px 20px;
    font-size: 14px;
    font-weight: 700;
    background-color: white;
  }

  .name {
    display: flex;
    align-items: center;

    img {
      height: 40px;
      width: 40px;
      min-height: 40px;
      min-width: 40px;
      margin-right: 10px;
      border-radius: 50%;
      object-fit: cover;
      object-position: center;
    }
  }
}

  .edit-image {
    cursor: pointer;
  }

  .delete-image {
    cursor: pointer;
  }
.no-admin-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 500px);
}
