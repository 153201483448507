/*Theme color*/
$primary-color: #07084B;
$primary-variant-color: #00A8FF;
$secondary-color: #ECEFF4;
$secondary-variant-color: #F9F9F9;
/*Background*/
$background-primary-color: #ffffff;
/*Event colors*/
$danger-color: #FF3334;
$info-color: #00A8FF;
$warning-color: #fabb3e;
$success-color: #469A20;
$primary-font-color: #212152;
$secondary-font-color: #464646;
$placeholder-color: #999999;
