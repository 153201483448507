@import "src/styles/index";

.error-message-list {
  width: 70%;
}

.error-message {
  margin-bottom: 10px;
  padding: 20px;
  cursor: pointer;
}
.no-error-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 300px);
  width: 100%;
}
