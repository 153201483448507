.action-buttons {
  display: flex;
  align-items: center;
}

.sub-type {
  display: flex;
  justify-content: space-between;
}

.mt-40 {
  margin-top: 40px;
}

.column {
  float: left;
  width: 33.33% !important;
  padding: 0 10px;
  flex: 1;
}

.column-100 {
  width: 100%;
  padding: 10px 10px;
}

.row {
  margin: 0 -5px;
}

.row:after {
  content: '';
  display: table;
  clear: both;
}

@media screen and (max-width: 600px) {
  .column {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
}

.card {
  height: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 16px;
  text-align: center;
  background-color: #F9F9F9 !important;
  border-radius: 10px !important;
  border: none !important;
  box-shadow: none !important;

  .chart-title {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 800;
    align-self: flex-start;
    margin-left: 0.7rem;
    margin-bottom: 1rem;
  }
}

.bar {
  canvas {
    width: 100% !important;
    // height: 500px !important;
  }
}

.container {
  margin-left: auto !important;
  margin-right: auto !important;
  height: 400px;
  position: relative;
}

.no-data-available-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 110px;
}

.no-data-style {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center
}
