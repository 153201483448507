@import 'src/styles/index';
@import './node_modules/bootstrap/scss/bootstrap';

.subscribed-buttons-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 30px 0;
}

.action-buttons {
  display: flex;
  align-items: center;
  button {
    height: 40px;
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 20px;
  }
  .success-button {
    margin-right: 20px;
  }
}

.mt-40 {
  margin-top: 40px !important;
}

.admin-title {
  margin-bottom: 5px;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 600;
  color: $placeholder-color;
}

  .td.email, .td.phone, .td.subscribe, .td.date, .td.total-payment {
    font-weight: 400!important;
  }

.fix-size{
  min-height: calc(100vh - 393px);
}
.admin-table {
  width: 100%;
  font-size: 14px;
  font-weight: 500;


  select {
    min-width: 100%;
    padding: 2px 10px;
    border: none;
    outline: none;
  }

  img {
    height: 38px;
    max-height: 38px;
  }

  .row-container {
    max-height: calc(100vh - 380px);
    overflow-y: auto;
  }

  .no-subscriber-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 500px);
  }

  .subscribe-table-details {
    display: grid;
    // grid-template-columns: calc(25% - 90px) calc(28% - 25px) calc(12% - 25px) calc(12% - 5px) calc(12% - 25px) calc(10% - 25px) 85px;
    grid-template-columns : calc(30% - 60px) calc(23% - 25px) calc(12% - 35px) calc(12% - 5px) calc(12% - 25px) calc(10% - 25px) 85px;
    align-items: center;
    gap: 15px;
  }

  .tr {
    align-items: center;
    padding: 15px 20px;
    margin-bottom: 10px;
    background-color: $secondary-variant-color;
    border-radius: 5px;
  }

  .subscribe-table-details .heading, .subscribe-table-details .name {
    font-weight: 500;
  }

  .subscribe-table-details td {
    font-weight: 400;
  }

  .admin-table-details.tr td {
    text-overflow: ellipsis;
    white-space: pre-wrap;
  }

  .heading {
    margin-bottom: 5px;
    padding: 0px 20px;
    font-size: 14px;
    font-weight: 700;
    background-color: white;
    color: black;
  }

  .heading div {
    white-space: pre-wrap;
  }

  .name {
    display: flex;
    align-items: center;

    img {
      height: 40px;
      width: 40px;
      min-height: 40px;
      min-width: 40px;
      margin-right: 10px;
      border-radius: 50%;
      object-fit: cover;
      object-position: center;
    }
  }
  .sub-type-admin {
    background: #FF3334;
    color: #fff;
    border-radius: 100px;
    width: -moz-fit-content;
    font-size: 10px;
    padding: 1px 7px;
    align-self:end;
  }
  .sub-type-vic{
    background: #f59e13;
  }
}

.sub-button {
  max-width: 185px;
  margin-right: 10px;
  padding: 15px 45px;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  white-space: nowrap;
  background-color: $secondary-variant-color;
  border-radius: 25px;
  cursor: pointer;
  text-align: center;
  width: 170px;
}
.download-btn{
  padding: 15px 45px;
  border-radius: 25px;
  width: 160px;
}
.add-invited-btn{
  padding: 15px 45px;
  border-radius: 25px;
  width: 160px;
}

.active-menu {
  color: #ffffff;
  background-color: #07084b;
}

.sub-common-area {
  margin-top: 20px;
}
.edit-image{
  cursor: pointer;
}
.delete-image{
  cursor: pointer;
}
