@import 'src/styles/index';

.settings-buttons-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 30px 0;

  .add-admin-btn{
    padding: 15px 45px;
    border-radius: 25px;
    width: 160px;
    text-align: center;
  }
}

.settings-button {
  max-width: 185px;
  width: 185px;
  margin-right: 10px;
  padding: 15px 45px;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  white-space: nowrap;
  background-color: $secondary-variant-color;
  border-radius: 25px;
  cursor: pointer;
  text-align: center;


}

// .settings-button:active,
// .settings-button:hover {
//   background: #4590e4;
// }

.active-menu {
  color: #ffffff;
  background-color: #07084b;
}

.settings-common-area {
  margin-top: 20px;
}


