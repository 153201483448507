@import 'color-palette';
@import 'utility';

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
* {
  font-family: 'Roboto', sans-serif !important;
}
body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $secondary-font-color !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

textarea {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
}

.ajax-global-spin {
  .ant-spin {
    height: 100vh !important;
    max-height: unset !important;
  }
}

.ajax-global-spin {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

select, .react-datepicker__input-container input {
  cursor: pointer!important;
}

select:disabled, input:disabled {
  cursor: not-allowed!important;
}

::placeholder {
  font-size: 12px!important;
  font-weight: 400;
  color: $placeholder-color;
}

/* completely hiding radio button */
input[type="radio"] {
  display: none;
}

/* simulate radiobutton appearance using pseudo selector */
input[type="radio"] + label:before {
  content: "";
  /* create custom radiobutton appearance */
  display: inline-block;
  height: 18px;
  width: 18px;
  min-height: 18px;
  min-width: 18px;
  padding: 3px;
  margin-right: 5px;
  /* background-color only for content */
  background-clip: content-box;
  border: 1px solid #bbbbbb;
  background-color: #e7e6e7;
  border-radius: 50%;
  cursor: pointer;
}

/* appearance of checked radiobutton */
input[type="radio"]:checked + label:before {
  background-color: $primary-color;
  border-color: $primary-color;
}

/* resetting default box-sizing */
*,
*:before,
*:after {
  box-sizing: border-box;
}

/* optional styles for centering radio buttons */
.radio-button {
  display: inline-flex;
  align-items: center;
  font-weight: 400;
  color: $placeholder-color;
}
