@import "src/styles/index";

.invited-table-details {
  display: grid;
  grid-template-columns: calc(30% - 45px) calc(38% - 45px) calc(15% - 15px)calc(15% - 15px) 100px;
  gap: 15px;
  overflow: hidden;
}
.delete-image{
  cursor: pointer;
}
