@import "src/styles/index";

.upper-header-block {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 7px 35px 0 35px;
  width: 100%;
  background-color: $secondary-variant-color;
}

.upper-header--rounded-block {
  display: flex;
  align-items: center;
  height: 46px;
  min-width: 218px;
  margin-bottom: 7px;
  padding: 3px;
  font-size: 12px;
  font-weight: 500;
  background-color: $background-primary-color;
  border-radius: 20px;

  label {
    width: calc(100% - 80px);
    margin: 0px 5px;
  }
}

.search-block {
  position: relative;
  margin-right: 20px;

  input {
    width: calc(100% - 55px);
    margin: 0 10px;
    font-size: 14px;
    border: none;
    outline: none;
  }

  .search-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    background-color: $success-color;
    outline: none;
    border: none;
    border-radius: 50%;

    img {
      height: 16px;
    }
  }
}

.user-dp {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.user-name {
  text-transform: capitalize;
  cursor: pointer;
}

.down-arrow {
  position: relative;
  display: inline-block;
  cursor: pointer;

  img {
    width: 16px;
  }
}

.logout-area{
  position: relative;
  display: inline-block;
}

.user-settings {
  margin-bottom: 7px;
}

.user-settings-container {
  margin-bottom: 0;
  background-color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.user-dropdown {
  display: block;
  position: absolute;
  top: 40px;
  right: -17px;
  width: 218px;
  background-color: #ffffff;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  overflow: hidden;
  z-index: 1;
}

.down-arrow:hover .user-dropdown {
  display: block;
}

.dropdown-option {
  display: flex;
  align-items: center;
  padding: 10px 16px;
  font-size: 12px;
  font-weight: 500;
  color: $placeholder-color;
  cursor: pointer;

  img {
    width: 20px;
    margin-right: 10px;
  }
  .menu-text{
    color: $secondary-font-color;}
}

.dropdown-option:not(:last-child) {
  border-bottom: 1px solid #BCBDBF;
}

.dropdown-option:hover {
  background-color: $secondary-variant-color;
}
.open-search-area{
  display: flex;
  align-items: center;
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 500;
  color: $placeholder-color;
  cursor: pointer;
}

.search-area{
  display: block;
  position: absolute;
  top: 45px;
  right: 10px;
  min-width: 233px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  overflow: hidden;
  z-index: 1;
}
