@import "src/styles/index";
@import './node_modules/bootstrap/scss/bootstrap';

.no-of-results-in-display {
  margin: 20px 0 10px 20px;
  font-size: 14px;
  font-weight: 400;
  color: $placeholder-color;
}

.quote-table {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  min-height: 78vh;

  select {
    width: 100%;
    padding: 2px 10px;
    background-color: $background-primary-color;
    border: none;
    border-radius: 3px;
    outline: none;
  }

  img {
    height: 38px;
    max-height: 38px;
  }

  .tr-container {
    max-height: calc(100vh - 240px);
    overflow-y: auto;
  }

  .tr {
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    margin-bottom: 15px;
    background-color: $secondary-variant-color;
    border-radius: 5px;
  }

  .td.quote {
    width: 40%;
    word-break: normal;
    overflow: hidden;
    text-overflow: ellipsis
  }

  .td.author {
    width: 20%;
    word-break: break-all;
  }

  .td.tags, .td.status {
    width: 15%;
  }

  .td.action {
    width: 100px;
  }

  .action img {
    cursor: pointer;
  }

 .td {
    padding-right: 20px;
  }

  .td.action {
    padding-right: 0;
  }

  .heading {
    margin-bottom: 5px;
    padding: 0 20px;
    background-color: white;
    color: black;
  }

  .tag-container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }

  .tag {
    margin: 5px 10px 0 0;
    padding: 2px 7px;
    line-height: 1.5;
    font-size: 12px;
    color: #ffffff;
    background-color: $info-color;
    border-radius: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

}
.action-buttons{
.add-quote-btn{
  padding: 15px 45px;
  border-radius: 25px;
  width: 160px;
  text-align: center;
}}

.no-quote-container, .no-invited-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 400px);
}
