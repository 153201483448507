@import "color-palette";

/* scrollbar starts here */
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $secondary-color;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary-color;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $primary-variant-color;
}
/* scrollbar ends here */

input {
  font-size: 14px;
  font-weight: 500;
  color: #464646;
}

.buttons-row {
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 20px 0 30px 0;
}

button:focus {
  outline: none !important;
}
.button {
  min-width: 140px;
  width: fit-content;
  padding: 10px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  border: none;
  border-radius: 20px;
  outline: none;
  cursor: pointer;
}

.success-button {
  color: white;
  background-color: $success-color;
}

.primary-button {
  color: white;
  background-color: $primary-variant-color;
}

.danger-button {
  color: white;
  background-color: $danger-color;
}

.white-button {
  color: $primary-color;
  border: 1px solid $primary-color!important;
  background-color: #FFFFFF;
}

.slim-button {
  padding: 5px 20px;
}

.common-select {
  min-width: 150px;
  height: 26px;
  background-color: $secondary-variant-color;
  font-size: 12px;
  font-weight: 600;
  color: $secondary-font-color;
  border: none;
  outline: none;
  border-radius: 5px;
}

.common-select option {
  font-size: 14px;
  font-weight: 600;
  color: $secondary-font-color;
}

canvas {
  width: 100%;
}

.common-input, .common-text-background {
  width: 100%;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 400;
  color: $secondary-font-color;
  background-color: $secondary-variant-color;
  border-radius: 5px;
  border: none;
  outline: none;
  resize: none;
}

.common-content {
  font-size: 14px;
  font-weight: 500;
  color: $secondary-font-color;
  outline: none;
}

.breadcrumb-custom {
  color: $primary-font-color;
  text-transform: uppercase;
  cursor: pointer;
  margin-bottom: 2rem;
}

.breadcrumb-custom span:last-child {
  color: $placeholder-color;
  cursor: text;
}

.common-title {
  font-size: 14px;
  font-weight: 700;
  color: $primary-font-color;
  margin-bottom: 30px;
}
.common-title-black {
  font-size: 14px;
  font-weight: 700;
  color:black;
  margin-bottom: 30px;
}
.common-bar-chart-title{
    font-size: 33px;
    padding: 40px;
    font-weight: 500;
    color: black;
}

.common-subtitle {
  font-size: 15px;
  font-weight: 700;
  color: $primary-font-color;
}

.placeholder-font {
  color: $placeholder-color !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.font-400 {
  font-weight: 400 !important;
}

.font-500 {
  font-weight: 500 !important;
}

.font-600 {
  font-weight: 600 !important;
}

.f-12 {
  font-size: 12px !important;
}

.f-14 {
  font-size: 14px !important;
}

.info-text-color {
  color: $info-color!important;
}

.d-flex {
  display: flex !important;
}

.w-100 {
  width: 100% !important;
}

.mar-bott-5 {
  margin-bottom: 5px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mr-5 {
  margin-right: 5px !important;
}
.ml-8{
  margin-left: 8px;
}
.ml-10 {
  margin-left: 10px;
 }
.ml-35{
  margin-left: 35px;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

/* pagination changes */
.pagination {
  padding-top: 20px!important;
  font-weight: 500!important;
}

.pagination > a, .pagination > .active > a
{
  color: $primary-color !Important;
  background-color: $secondary-color !Important;
  border: none !Important;
  border-radius: 10px !important;
  outline: none!important;
}
.page-item .page-link {
  margin: 0 5px!important;
  color: $primary-font-color !Important;
  border: none!important;
  border-radius: 10px !important;
}
.page-link:hover {
  border-radius: 10px !important;
}
.page-link:focus {
  box-shadow: none!important;
}

.page-item.disabled {
  cursor: not-allowed!important;
}

.page-item.disabled .page-link {
  color: $placeholder-color!important;
}

.graph-container {
  display: flex;
  .graph {
    max-width: calc(100% - 140px);
    padding: 5px;
  }
}

#pie-legends, #gender-legends, #subscription-legends {
  min-width: 140px;
  li div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    min-width: 45px;
    margin-bottom: 5px;
    padding: 5px;
    font-weight: 600;
    color: white;
    line-height: 1;
    background-color: darkolivegreen;
    border: 2px solid white;
    border-radius: 3px;
    box-sizing: border-box;
  }
  li label {
    font-size: 14px;
    font-weight: 500;
    margin-left: 10px;
  }
  ul {
    margin: 0;
    padding: 0;
    text-align: left;
    font-size: 12px;
    color: $secondary-font-color;
    list-style: none;

    li {
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
  }
}

