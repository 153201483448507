@import "src/styles/index";

.add-edit-quote-block {
  margin-top: 20px;
  width: 60%;
  min-width: 500px;
}

.author-detail {
  display: flex;

  div {
    margin-right: 20px;
  }
}

.add-edit-quote-button {
  min-width: 170px;
  width: 15%;
  margin-right: 15px;
}
