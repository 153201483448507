$width: 100;
$color: #FFFFFF;

.loader-wrapper {
    position: fixed;
    z-index: 9999;
    height: 2em;
    width: 2em;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.loader-wrapper:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}
.ball-loader{
    width: $width + 0px;
    height: ($width / 3) - 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}
.ball-loader-ball{
    will-change: transform;
    height:($width / 3) - 10px;
    width: ($width / 3) - 10px;
    border-radius: 50%;
    background-color: $color;
    position: absolute;
    animation: grow 1s ease-in-out infinite alternate;


    &.ball1{
        left:0;
        transform-origin: 100% 50%;
    }
    &.ball2{
        left:50%;
        transform: translateX(-50%) scale(1);
        animation-delay: 0.33s;
    }
    &.ball3{
        right:0;
        animation-delay: 0.66s;
    }
}

@keyframes grow{
    to{
        transform: translateX(-50%) scale(0);
    }
}