@import "src/styles/index";

.modal-main-container {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0,0,0,0.2);
  z-index: 9999;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  overflow: hidden;
}

.modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 350px;
  margin: auto;
  background-color: white;
  border-radius: 10px;
}

.modal-main-title {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  color: $secondary-font-color;
  border-bottom: 1px solid $placeholder-color;
}

.modal-description {
  padding: 20px;
  font-size: 14px;
  font-weight: 400;
  color: $secondary-font-color;
}


.modal-container .buttons-row {
  text-align: right;
  button {
    margin-right: 10px;
    padding: 10px 20px;
  }
  button:last-child {
    margin-right: 0;
  }
}
