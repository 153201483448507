@import "src/styles/index";
@import "src/components/authentication/authStyle";

.login-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  margin: auto;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  max-width: 320px;
  min-width: 200px;
}

.show-hide-pswrd {
  width: 18px;
  cursor: pointer;
}

.login-button {
  margin: 25px auto 10px auto;
}

.forgot-password {
  font-size: 12px;
  color: $primary-color;
}
